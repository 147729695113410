import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { renderApps } from './utils/try-convert'

import VenueHeader from './venue/Head'

const Venue = ({ data: { loading, error, officeSearch, studentsTryitKpi, studentsTryitNotSetSearch, studentsPlanNotCreateSearch } }) => {
  if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>
  if (!loading) {
    console.log(officeSearch)
    console.log(studentsTryitNotSetSearch)
    console.log(studentsPlanNotCreateSearch)
    return (
      <>
        {officeSearch.map(office => (
        <Grid container spacing={3}>
          <VenueHeader officeCode={office.officeCode} officeName={office.officeName} />
        </Grid>
        ))}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              利用状況
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3" display="inline">
              {studentsTryitKpi.thisWeekKpi}
            </Typography>
            <Typography display="inline">
              &nbsp;%／今週
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h3" display="inline">
              {studentsTryitKpi.lastWeekKpi}
            </Typography>
            <Typography display="inline">
              &nbsp;%／先週
            </Typography>
          </Grid>
        </Grid>

        <Box my={5}>
          <Typography variant="h6" component="h1" gutterBottom>
            セットアップがまだの生徒
          </Typography>
          {studentsTryitNotSetSearch.length ? (
            <Paper my={5}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">生徒氏名</TableCell>
                    <TableCell align="left">生徒コード</TableCell>
                    <TableCell align="left">学年</TableCell>
                    <TableCell align="left">担当社員名</TableCell>
                    <TableCell align="left">契約日</TableCell>
                    <TableCell align="left">QRコード</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentsTryitNotSetSearch.map(user => (
                    <TableRow key={user.studentCode}>
                      <TableCell component="th" scope="row" align="left">{user.studentName}</TableCell>
                      <TableCell align="left">{user.studentCode}</TableCell>
                      <TableCell align="left">{user.schoolYearName}</TableCell>
                      <TableCell align="left">{user.staffName}</TableCell>
                      <TableCell align="left">{format(user.tabletContractDate, 'YYYY年 MMMDo', {locale: ja})}</TableCell>
                      <TableCell align="left"><a href={`/qr-student/${user.studentCode}/${encodeURIComponent(user.studentName)}/${user.ashigaruAuthToken}`} target="_brank">表示</a></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <p>未セットアップの生徒はいません。</p>
          )}
        </Box>

        <Box my={5}>
          <Typography variant="h6" component="h1" gutterBottom>
            プラン作成がまだの生徒
          </Typography>
          {studentsPlanNotCreateSearch.length ? (
            <Paper my={5}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">生徒氏名</TableCell>
                    <TableCell align="left">生徒コード</TableCell>
                    <TableCell align="left">学年</TableCell>
                    <TableCell align="left">担当社員名</TableCell>
                    <TableCell align="left">利用中のアプリケーション</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentsPlanNotCreateSearch.map(user => (
                    <TableRow key={user.studentCode}>
                      <TableCell component="th" scope="row" align="left">{user.studentName}</TableCell>
                      <TableCell align="left">{user.studentCode}</TableCell>
                      <TableCell align="left">{user.schoolYearName}</TableCell>
                      <TableCell align="left">{user.staffName}</TableCell>
                      <TableCell align="left">{renderApps(user.ashigaruApplicationTypeDivision)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <p>プラン作成がまだの生徒はいません。</p>
          )}
        </Box>
      </>
    )
  }
  return <p>店舗情報を取得中...</p>
}

Venue.propTypes = {
  userCode: PropTypes.number,
  // officeCode: PropTypes.String,
}

export const venueQuery = gql`
  query store($officeCode: String!) {
    studentsTryitKpi(officeCode: $officeCode){
      thisWeekKpi
      lastWeekKpi
    }
    officeSearch(officeCode: $officeCode){
      officeCode
      officeName
      serviceType
    }
    studentsTryitNotSetSearch (officeCode: $officeCode ){
      studentCode
      ashigaruAuthToken
      studentName
      schoolYearCode
      schoolYearName
      schoolCode
      schoolName
      staffCode
      staffName
      officeCode
      officialOfficeName
      isMiddleSchoolStudent
      tabletContractDate
      ashigaruApplicationUseAt
      canManagementScreenDisplay
    }
    studentsPlanNotCreateSearch(officeCode: $officeCode){
      studentCode
      studentName
      schoolYearCode
      schoolYearName
      staffCode
      staffName
      ashigaruApplicationTypeDivision
    }
  }
`

export default graphql(venueQuery, {
  options: ({ match }) => ({
    variables: {
      officeCode: match.params.officeCode
    }
  })
})(Venue)
