import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'

import QrPrintButton from '../utils/qr-print'
import JsHsButton from '../utils/js-hs'

class VenueHeader extends Component {
  state = {
    officeCode: this.props.officeCode,
    officeName: this.props.officeName
  }

  render() {
    return (
      <>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box my={2}>
            <Typography variant="h5" component="h1" gutterBottom>
              {this.state.officeName}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              店舗コード: {this.state.officeCode}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} alignItems="center">
          <Box mt={2} align="right">
            <JsHsButton officeCode={this.state.officeCode} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} alignItems="center">
          <Box mt={2} align="right">
            <QrPrintButton officeCode={this.state.officeCode} officeName={this.state.officeName} />
          </Box>
        </Grid>
      </>
    )
  }
}

export default VenueHeader
