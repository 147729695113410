import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Header from './Header'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Venue from './Venue'
import VenueSearch from './VenueSearch'
import Student from './Student'
import StudentSearch from './StudentSearch'
import QRStudent from './QR-Student'
import QRVenue from './QR-Venue'
import Reports from './student/Reports'

const LOGIN_USER = gql`
  query{
    loginUsers {
      employeeCode
      employeeName
      officeCode
      officeName
    }
  }
`

class App extends Component {
  state = {
    employeeCode: ``,
    employeeName: ``,
    officeCode: ``,
    officeName: ``,
    error: '',
  }

  render() {
    return (
      <Query query={LOGIN_USER}>
        {({ data, loading, error }) => {
          if (loading) return <p>ログイン中...</p>
          if (error) return <p>管理画面を利用するには、<a href="http://172.27.3.20/">基幹システムへのログイン</a>が必要です。</p>

          console.log(data.loginUsers)

          return (
            <>
              <Header loginUsers={data.loginUsers} />
              <Container>
                <Box my={4}>
                  <Switch>
                    <Route exact path="/">
                      {data.loginUsers && <Redirect to={`/venue/${data.loginUsers.officeCode}`} />}
                    </Route>
                    <Route exact path="/venue/:officeCode" component={Venue} />
                    <Route exact path="/venue-search/" component={VenueSearch} />
                    <Route exact path="/student/:studentCode/:viewMode" component={Student} />
                    <Route exact path="/student/:studentCode" component={Student} />
                    <Route exact path="/student/:officeCode/:studentCode/reports" component={Reports} />
                    <Route exact path="/student-search/:officeCode" component={StudentSearch} />
                    <Route exact path="/qr-student/:studentCode/:studentName/:authToken" component={QRStudent} />
                    <Route exact path="/qr-venue/:officeCode/:officeName/:authToken" component={QRVenue} />
                  </Switch>
                </Box>
              </Container>
            </>
          )
        }}
      </Query>
    )
  }
}

export default App
