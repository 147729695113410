import React from 'react'
import PropTypes from 'prop-types'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import JukenSubjects from './JukenSubjects'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const JukenLists = ({ data: { loading, error, hJukenUserSchedules, variables } }) => {
  const [value, setValue] = React.useState(0)
  console.log(variables.studentCode)
  console.log(variables.fiscalYear)
  // const studentCode = variables.studentCode

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  if (error) return <p>スケジュールを取得できません。システム管理者にお問い合せください。</p>
  if (!loading) {

    const sortScheduled = function (a, b) {
      if (Number(a.scheduleId) < Number(b.scheduleId)) return -1
      if (Number(a.scheduleId) > Number(b.scheduleId)) return 1
    }

    const userSchedules = hJukenUserSchedules.sort(sortScheduled)
    console.log(userSchedules)

    const scheduleId = (id) => {
      if (userSchedules.filter(x => x.month === id).length) {
        return true
      } else {
        return false
      }
    }

    return (
      <>
        <Box my={5}>
          <Tabs value={value} onChange={handleChange} indicatorColor="default" textColor="primary" variant="scrollable" scrollButtons="auto">
            {!scheduleId(4) &&
              <Tab label="4月" disabled style={{display: 'none'}} />
            }
            {scheduleId(4) &&
              <Tab label="4月" />
            }
            {!scheduleId(5) &&
              <Tab label="5月" disabled style={{display: 'none'}} />
            }
            {scheduleId(5) &&
              <Tab label="5月" />
            }
            {!scheduleId(6) &&
              <Tab label="6月" disabled style={{display: 'none'}} />
            }
            {scheduleId(6) &&
              <Tab label="6月" />
            }
            {!scheduleId(7) &&
              <Tab label="7月" disabled style={{display: 'none'}} />
            }
            {scheduleId(7) &&
              <Tab label="7月" />
            }
            {!scheduleId(8) &&
              <Tab label="8月" disabled style={{display: 'none'}} />
            }
            {scheduleId(8) &&
              <Tab label="8月" />
            }
            {!scheduleId(9) &&
              <Tab label="9月" disabled style={{display: 'none'}} />
            }
            {scheduleId(9) &&
              <Tab label="9月" />
            }
            {!scheduleId(10) &&
              <Tab label="10月" disabled style={{display: 'none'}} />
            }
            {scheduleId(10) &&
              <Tab label="10月" />
            }
            {!scheduleId(11) &&
              <Tab label="11月" disabled style={{display: 'none'}} />
            }
            {scheduleId(11) &&
              <Tab label="11月" />
            }
            {!scheduleId(12) &&
              <Tab label="12月" disabled style={{display: 'none'}} />
            }
            {scheduleId(12) &&
              <Tab label="12月" />
            }
            {!scheduleId(1) &&
              <Tab label="1月" disabled style={{display: 'none'}} />
            }
            {scheduleId(1) &&
              <Tab label="1月" />
            }
            {!scheduleId(2) &&
              <Tab label="2月" disabled style={{display: 'none'}} />
            }
            {scheduleId(2) &&
              <Tab label="2月" />
            }
            {!scheduleId(3) &&
              <Tab label="3月" disabled style={{display: 'none'}} />
            }
            {scheduleId(3) &&
              <Tab label="3月" />
            }
          </Tabs>
          <Box my={5}>
            <Typography variant="h6" gutterBottom paragraph>
              設定中の科目と分野
            </Typography>
          </Box>
          {value === 0 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 4) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 4)} />}
            </TabContainer>}
          {value === 1 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 5) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 5)} />}
            </TabContainer>}
          {value === 2 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 6) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 6)} />}
            </TabContainer>}
          {value === 3 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 7) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 7)} />}
            </TabContainer>}
          {value === 4 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 8) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 8)} />}
            </TabContainer>}
          {value === 5 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 9) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 9)} />}
            </TabContainer>}
          {value === 6 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 10) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 10)} />}
            </TabContainer>}
          {value === 7 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 11) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 11)} />}
            </TabContainer>}
          {value === 8 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 12) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 12)} />}
            </TabContainer>}
          {value === 9 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 1) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 1)} />}
            </TabContainer>}
          {value === 10 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 2) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 2)} />}
            </TabContainer>}
          {value === 11 &&
            <TabContainer >
              {userSchedules.filter(x => x.month === 3) &&
                <JukenSubjects groupScheduled={userSchedules.filter(x => x.month === 3)} />}
            </TabContainer>}

        </Box>
      </>
    )
  }
  return <p>スケジュールを取得中...</p>
}

JukenLists.propTypes = {
  studentCode: PropTypes.number,
  fiscalYear: PropTypes.number
}

export const jukenQuery = gql`
  query store($studentCode: ID!, $fiscalYear: Int!) {
    hJukenUserSchedules(studentCode: $studentCode, fiscalYear: $fiscalYear){
      fiscalYear
      id
      month
      userScheduleSubjectCategories {
        id
        subjectCategoryId
        unitCategories {
          id
          name
          subjectId
        }
      }
      year
    }
  }
`

export default graphql(jukenQuery, {
  options: (props) => ({
    variables: {
      studentCode: props.studentCode,
      fiscalYear: props.fiscalYear
    }
  })
})(JukenLists)

