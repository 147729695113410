import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Link from '@material-ui/core/Link'

const VENUE_QUERY = gql`
  query officeSearch ($officeCode: String, $officeName: String) {
    officeSearch (officeCode: $officeCode, officeName: $officeName){
      officeCode
      officeName
      serviceType
    }
  }
`

class VenueSearch extends Component {
  state = {
    stores: [],
    officeName: '',
    officeCode: '',
    isLoading: false
  }

  render () {
    return (
      <>
        <Grid item xs={12}>
          <Box my={5}>
            <Typography variant="h6" component="h1" gutterBottom>
              店舗検索
            </Typography>
            <p>店舗名、または店舗コード（半角数字4桁）のいずれかを入れて検索してください。</p>
          </Box>
          <Box my={4}>
            <form autoComplete="off">
              <TextField
                id="officeName"
                label="店舗名"
                type="text"
                onChange={e => this.setState({ officeName: e.target.value })}
                margin="normal"
              />
              {`　`}
              <TextField
                id="officeCode"
                label="店舗コード"
                type="number"
                onChange={e => this.setState({ officeCode: e.target.value })}
                margin="normal"
              />
              {`　`}
              <Fab size="large" variant="extended" color="primary" disabled={this.state.officeName.length || this.state.officeCode.length ? false : true} onClick={() => this._executeSearch()}>
                　検索する　
              </Fab>
            </form>
          </Box>
        </Grid>
        <Box my={5}>
          <Typography variant="h6" component="h1" gutterBottom>
            検索結果
          </Typography>
          {this.state.isLoading ? <CircularProgress /> : ''}
          {this.state.stores.length ? (
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>店舗名</TableCell>
                    <TableCell align="left">店舗コード</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.stores.map(store => (
                    <TableRow key={store.officeCode}>
                      <TableCell component="th" scope="row">
                        <Link to={`/venue/${store.officeCode}`} store={store}>{store.officeName}</Link>
                      </TableCell>
                      <TableCell align="left">{store.officeCode}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <p>検索結果は0件です。</p>
          )}
        </Box>
      </>
    )
  }

  _executeSearch = async () => {
    const { officeName, officeCode } = this.state
    this.setState({ stores: []})
    this.setState({ isLoading: true })
    const result = await this.props.client.query({
      query: VENUE_QUERY,
      variables: {
        officeName,
        officeCode
      },
    })
    const stores = result.data.officeSearch
    console.log(stores)
    this.setState({ isLoading: false })
    this.setState({ stores })
  }

}

export default withApollo(VenueSearch)
