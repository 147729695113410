import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Icon from '@material-ui/core/Icon'

import ReportHistory from './ReportHistory'
// import { renderGrade } from './utils/try-convert'

const StudentHeader = ({ data: { loading, error, studentTryitDetail, surveyMidSchMonthlyAnswers } }) => {
  console.log(studentTryitDetail)
  console.log(surveyMidSchMonthlyAnswers)

  if (error) return <p>生徒情報の取得に失敗しました。システム管理者にお問い合せください。</p>
  if (!loading) {

    const latestByApp = studentTryitDetail.latestByApp
    const latestAppLog = (appId) => {
      const latestLog = latestByApp.filter(x => x.ashigaruApplicationTypeDivision === appId)
      if (latestLog[0]) {
        return format(latestLog[0].ashigaruApplicationUseAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})
      } else {
        return `--`
      }
    }
    // console.log(latestAppLog('30'))
    // console.log(latestAppLog('20'))

    const appOptionCodes = studentTryitDetail.optionApplicationCodes
    const appOptions = () => {
      if (appOptionCodes.includes('3101')) {
        return 'あり'
      } else if (appOptionCodes.length === 0) {
        return 'なし'
      }
    }

    console.log(appOptions())

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5}>
            <Box mt={2}>
              <Typography variant="h4" component="h1" gutterBottom>
                {studentTryitDetail.studentName} さん
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} alignItems="center">
            <Box mt={2} align="center">
              <ButtonGroup>
                <Button variant="outlined" color="primary" size="small" href={`/student/${studentTryitDetail.studentCode}/t`}>
                  <Icon>note</Icon>&nbsp;定期テスト対策
                </Button>
                {` `}
                <Button variant="outlined" color="primary" size="small" href={`/student/${studentTryitDetail.studentCode}/j`}>
                  <Icon>note</Icon>&nbsp;受験対策
                </Button>
                <Button variant='outlined' color="primary" size="small" href={`/student/${studentTryitDetail.studentCode}/d`}>
                  <Icon>list</Icon>&nbsp;学習診断
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} alignItems="center">
            <Box mt={2} align="right">
              <ReportHistory answers={surveyMidSchMonthlyAnswers} officeCode={studentTryitDetail.officeCode} studentCode={studentTryitDetail.studentCode} />&nbsp;
              <Button variant="outlined" color="default" size="small" href={`/qr-student/${studentTryitDetail.studentCode}/${encodeURIComponent(studentTryitDetail.studentName)}/${studentTryitDetail.ashigaruAuthToken}`} target="_brank">
                <Icon>crop_free</Icon>&nbsp;QRコード
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              生徒情報
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="left">生徒コード:&nbsp;{studentTryitDetail.studentCode}</TableCell>
                    <TableCell align="left">所属店舗:&nbsp;{studentTryitDetail.officialOfficeName}</TableCell>
                    <TableCell align="left">最終利用日（定期テスト対策）: &nbsp;{latestAppLog('30')}</TableCell>
                    <TableCell align="left">&nbsp;</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">学校:&nbsp;{studentTryitDetail.schoolName}</TableCell>
                    <TableCell align="left">契約日: &nbsp;{format(studentTryitDetail.tabletContractDate, 'YYYY年 MMMDo', {locale: ja})}</TableCell>
                    <TableCell align="left">最終利用日（受験対策）: &nbsp;{latestAppLog('31')}</TableCell>
                    <TableCell align="left">&nbsp;</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">学年:&nbsp;{studentTryitDetail.schoolYearName}</TableCell>
                    <TableCell align="left">対策問題オプション: {appOptions()}</TableCell>
                    <TableCell align="left">最終報告日: &nbsp;{!studentTryitDetail.lastMonthlyAnsweredAt ? `--` : `${format(studentTryitDetail.lastMonthlyAnsweredAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}`}</TableCell>
                    <TableCell align="left">&nbsp;</TableCell>
                  </TableRow>
                  {studentTryitDetail.entranceExam !== null &&
                    <TableRow>
                      <TableCell align="left" colSpan={3}>志望校:&nbsp;{studentTryitDetail.entranceExam.faculty.university.name}&nbsp;{studentTryitDetail.entranceExam.faculty.name}&nbsp;{studentTryitDetail.entranceExam.caption}</TableCell>
                    </TableRow>
                  }
                  {studentTryitDetail.entranceExam === null &&
                    <TableRow>
                      <TableCell align="left" colSpan={3}>志望校:&nbsp;--</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    )
  }
  return <p>生徒情報を取得中...</p>
}

StudentHeader.propTypes = {
  studentCode: PropTypes.number
}

export const StudentQuery = gql`
  query store ($studentCode: ID) {
    studentTryitDetail (studentCode: $studentCode){
      studentCode
      ashigaruAuthToken
      studentName
      schoolYearCode
      schoolYearName
      schoolCode
      schoolName
      staffCode
      staffName
      officeCode
      officialOfficeName
      isMiddleSchoolStudent
      ashigaruApplicationTypeDivision
      tabletContractDate
      ashigaruApplicationUseAt
      canManagementScreenDisplay
      latestByApp {
        ashigaruApplicationTypeDivision
        ashigaruApplicationUseAt
      }
      lastMonthlyAnsweredAt
      optionApplicationCodes
      entranceExam {
        actualAdvancedContent
        advancedContent
        caption
        faculty{
          name
          university{
            name
          }
        }
      }
    }
    surveyMidSchMonthlyAnswers(studentCode: $studentCode){
      schoolDivisionCode
      targetYearMonth
      updatedAt
    }
  }
`

export default graphql(StudentQuery, {
  options: (props) => ({
    variables: {
      studentCode: props.studentCode,
    }
  })
})(StudentHeader)
