import React, { Component } from 'react'

// import { format } from 'date-fns'
// import ja from 'date-fns/locale/ja'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { renderHighUnit } from '../utils/try-convert'

class TeikiData extends Component {
  state = {
    groupUnit: this.props.groupUnit,
  }

  render() {

    console.log(this.state.groupUnit)

    return (
      <Grid container spacing={5}>
        {this.state.groupUnit.units.map(unit => (
        <Grid item xs={12} sm={6}>
          <Box mt={1}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="left">{renderHighUnit(unit.unitCategoryId)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {unit.results.map(result => (
                    <TableRow>
                      <TableCell align="left">
                        {result.name}
                      </TableCell>
                      <TableCell align="right">
                        {result.userUnit && result.userUnit.understandingLevelForCheck &&
                          `★${result.userUnit.understandingLevelForCheck}`
                        }
                        {result.userUnit && !result.userUnit.understandingLevelForCheck &&
                          `★${result.userUnit.understandingLevelForShindan}`
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        ))}
      </Grid>

    )
  }
}

export default TeikiData
