import React, { Component } from 'react'

import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import axios from 'axios'
import FileSaver from 'file-saver'

const GET_PDF = gql`
  query store($studentCode: ID!, $subjectId: ID!) {
    learningDiagnosticSheet(studentCode: $studentCode, subjectId: $subjectId){
      studentCode
      subjectId
      id
      ashigaruApplicationTypeDivision
    }
  }
`

class DiagnosisLinkBySubject extends Component {
  state = {
    studentCode: this.props.studentCode,
    subjectId: this.props.subjectId,
    subjectName: this.props.subjectName
  }

  render () {
    return (
      <>
        <Button size="small" variant="outlined" color="primary" onClick={() => this._getFile()}>
          <Icon>attachment</Icon>&nbsp;{this.state.subjectName}の最新版診断結果ダウンロード
        </Button>
      </>
    )
  }

  _getFile = async () => {
    const { studentCode, subjectId } = this.state

    const result = await this.props.client.query({
      query: GET_PDF,
      variables: { studentCode, subjectId },
    })
    const store = result.data.learningDiagnosticSheet
    // console.log(store)
    const url = `https://dev2a-bff.leap.trygroup.co.jp/customers/students/tryit/diagnosis/get/?student_code=${store.studentCode}&subject_id=${store.subjectId}`

    // const fistToken = 'devdevdevdevdevdevdevdevdevdevdevdevdevd'
    const fistToken = localStorage.getItem('authToken')
    console.log(fistToken)

    await axios.get(url, {
      headers: { 'X-Auth-Token': `${fistToken}` },
      responseType: 'blob'
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })

      const contentDisposition = response.headers['content-disposition']
      // console.log(contentDisposition)

      let fileName = 'unknown'
      if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch.length === 2)
              fileName = fileNameMatch[1]
      }

      FileSaver.saveAs(blob, fileName)
    })
  }
}

export default withApollo(DiagnosisLinkBySubject)
