import React, { Component } from 'react'

import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import SortingTable from './SortingTable'

const USERS_QUERY = gql`
  query studentsTryitSearch ($officeCode: String!, $studentCode: [ID], $studentName: String, $staffName: String, $schoolGroup: [String]) {
    studentsTryitSearch (officeCode: $officeCode, studentCode: $studentCode, studentName: $studentName, staffName: $staffName, schoolGroup: $schoolGroup ) {
      studentCode
      ashigaruAuthToken
      studentName
      schoolYearCode
      schoolYearName
      schoolCode
      schoolName
      staffCode
      staffName
      officeCode
      officialOfficeName
      isMiddleSchoolStudent
      ashigaruApplicationTypeDivision
      tabletContractDate
      ashigaruApplicationUseAt
      canManagementScreenDisplay
    }
  }
`

class ComponentSearchUsers extends Component {
  state = {
    users: [],
    officeCode: this.props.officeCode,
    studentName: '',
    studentCode: '',
    staffName: '',
    schoolJs: false,
    schoolHs: false,
    schoolGroup: [],
    isLoading: false
  }

  render () {
    return (
      <>
        <Box mt={2} mb={5}>
          <Typography variant="h6" component="h1" gutterBottom>
            生徒検索
          </Typography>
          <p>生徒氏名、生徒コード（半角数字11桁）、担当者名のいずれかを入力して検索してください。空で検索すると全件取得します。<br />
          <small>※他店舗の生徒を検索する場合は、下記の店舗コード（半角数字4桁）を変更して検索を実行してください</small></p>

          <form autoComplete="off">
            <Grid container spacing={2}>
              <Grid item sm={2}>
                <TextField
                  id="officeCode"
                  label="店舗コード"
                  value={this.state.officeCode}
                  type="text"
                  onChange={e => this.setState({ officeCode: e.target.value })}
                  margin="normal"
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 4,
                    minLength: 4
                  }}
                  helperText="店舗コードは必須"
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  id="studentName"
                  label="生徒氏名"
                  type="text"
                  onChange={e => this.setState({ studentName: e.target.value })}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  id="studentCode"
                  label="生徒コード"
                  type="text"
                  inputProps={{
                    maxLength: 11,
                    minLength: 11
                  }}
                  onChange={e => this.setState({ studentCode: e.target.value })}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  id="staffName"
                  label="担当者名"
                  type="text"
                  onChange={e => this.setState({ staffName: e.target.value })}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item sm={2}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.schoolJs} onChange={e => this.setState({ schoolJs: e.target.checked })} value="20" color="primary" />
                    }
                    label="中学生"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.schoolHs} onChange={e => this.setState({ schoolHs: e.target.checked })} value="30" color="primary" />
                    }
                    label="高校生"
                    labelPlacement="top"
                  />
                </FormGroup>
              </Grid>
              <Grid item sm={2}>
                <Fab size="large" variant="extended" color="primary" onClick={() => this._executeSearch()}>
                  　　検索する　　
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box my={10}>
          <Typography variant="h6" component="h1" gutterBottom>
            検索結果
          </Typography>
          {this.state.isLoading ? <CircularProgress /> : ''}
          {this.state.users.length ? (
            <SortingTable data={this.state.users} officeCode={this.state.officeCode} />
            ) : (
            <Box mb={3}><p>検索結果は0件です。</p></Box>
            )
          }
        </Box>
      </>
    )
  }

  _executeSearch = async () => {
    if (this.state.schoolJs && this.state.schoolHs) { this.state.schoolGroup = ['20', '30'] }
    if (this.state.schoolJs && !this.state.schoolHs) { this.state.schoolGroup = ['20'] }
    if (!this.state.schoolJs && this.state.schoolHs) { this.state.schoolGroup = ['30'] }
    if (!this.state.schoolJs && !this.state.schoolHs) { this.state.schoolGroup = [] }

    const { officeCode, studentCode, studentName, staffName, schoolGroup } = this.state
    this.setState({ users: []})
    this.setState({ isLoading: true })

    console.log(this.state)

    const result = await this.props.client.query({
      query: USERS_QUERY,
      variables: {
        officeCode,
        studentCode,
        studentName,
        staffName,
        schoolGroup,
      }
    })
    const users = result.data.studentsTryitSearch
    console.log(users)
    this.setState({ isLoading: false })
    this.setState({ users })
  }

}

export default withApollo(ComponentSearchUsers)
