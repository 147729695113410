import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { renderHighSubject } from '../utils/try-convert'
import JukenData from './JukenData'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const JukenSubjects = (props) => {
  const [value, setValue] = React.useState(0)

  const scheduledList = props.groupScheduled
  const subjectList = scheduledList.reduce((result, current) => {
      result = current;
      return result;
  }, {});
  console.log(subjectList)

  let selectedSchedule = []

  if (subjectList.userScheduleSubjectCategories === undefined) {
    selectedSchedule = []
  } else {
    selectedSchedule = subjectList.userScheduleSubjectCategories
  }

  console.log(selectedSchedule)

  const groupUnit = selectedSchedule.map(item => {
    const units = item.unitCategories.reduce((result, current) => {
      const element = result.find((p) => {
        return p.subjectId === current.subjectId
      })
      if (element) {
        element.results.push({
          id: current.id,
          name: current.name,
        })
      } else {
        result.push({
          subjectId: current.subjectId,
          results: [{
            id: current.id,
            name: current.name,
          }]
        })
      }
      return result
    }, [])
    return (
      units
    )
  })

  console.log(groupUnit)

  const subjectUnit = groupUnit.reduce((result, current) => {
    result.push(...current)
    return result
  }, [])

  console.log(subjectUnit)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  return (
    <>
      <Box>
        <Tabs value={value} onChange={handleChange} indicatorColor="default" textColor="primary" variant="scrollable" scrollButtons="auto">
          {selectedSchedule && subjectUnit.map(result => (
            <Tab label={renderHighSubject(result.subjectId)} />
          ))}
        </Tabs>
        {value === 0 &&
          <TabContainer>
            {subjectUnit[0] && <JukenData groupUnit={subjectUnit[0]} />}
          </TabContainer>}
        {value === 1 &&
          <TabContainer>
            {subjectUnit[1] && <JukenData groupUnit={subjectUnit[1]} />}
          </TabContainer>}
        {value === 2 &&
          <TabContainer>
            {subjectUnit[2] && <JukenData groupUnit={subjectUnit[2]} />}
          </TabContainer>}
        {value === 3 &&
          <TabContainer>
            {subjectUnit[3] && <JukenData groupUnit={subjectUnit[3]} />}
          </TabContainer>}
        {value === 4 &&
          <TabContainer>
            {subjectUnit[4] && <JukenData groupUnit={subjectUnit[4]} />}
          </TabContainer>}
        {value === 5 &&
          <TabContainer>
            {subjectUnit[5] && <JukenData groupUnit={subjectUnit[5]} />}
          </TabContainer>}
        {value === 6 &&
          <TabContainer>
            {subjectUnit[6] && <JukenData groupUnit={subjectUnit[6]} />}
          </TabContainer>}
        {value === 7 &&
          <TabContainer>
            {subjectUnit[7] && <JukenData groupUnit={subjectUnit[7]} />}
          </TabContainer>}
        {value === 8 &&
          <TabContainer>
            {subjectUnit[8] && <JukenData groupUnit={subjectUnit[8]} />}
          </TabContainer>}
        {value === 9 &&
          <TabContainer>
            {subjectUnit[9] && <JukenData groupUnit={subjectUnit[9]} />}
          </TabContainer>}
        {value === 10 &&
          <TabContainer>
            {subjectUnit[10] && <JukenData groupUnit={subjectUnit[10]} />}
          </TabContainer>}
        {value === 11 &&
          <TabContainer>
            {subjectUnit[11] && <JukenData groupUnit={subjectUnit[11]} />}
          </TabContainer>}
        {value === 12 &&
          <TabContainer>
            {subjectUnit[12] && <JukenData groupUnit={subjectUnit[12]} />}
          </TabContainer>}
        {value === 13 &&
          <TabContainer>
            {subjectUnit[13] && <JukenData groupUnit={subjectUnit[13]} />}
          </TabContainer>}
        {value === 14 &&
          <TabContainer>
            {subjectUnit[14] && <JukenData groupUnit={subjectUnit[14]} />}
          </TabContainer>}
        {value === 15 &&
          <TabContainer>
            {subjectUnit[15] && <JukenData groupUnit={subjectUnit[15]} />}
          </TabContainer>}
      </Box>
    </>
  )
}

export default JukenSubjects
