import React, { Component } from 'react'

// import { format } from 'date-fns'
// import ja from 'date-fns/locale/ja'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import DiagnosisLinkById from './DiagnosisById'
import DiagnosisLinkBySubject from './DiagnosisBySubject'

class DiagnosisData extends Component {
  state = {
    groupUnit: this.props.groupUnit,
    studentCode: this.props.studentCode
  }

  render() {

    console.log(this.props)
    // const authToken = localStorage.getItem('authToken')
    // console.log(authToken)

    return (
      <Grid container spacing={5}>
        <Grid item xs={12} align="right">
          <Box mx={2} mt={2} mb={0}>
            <DiagnosisLinkBySubject studentCode={this.state.studentCode} subjectId={this.state.groupUnit.subjectId} subjectName={this.state.groupUnit.subjectName} />
          </Box>
        </Grid>
        {this.state.groupUnit.units.map(unit => (
        <Grid item xs={12} sm={6}>
          <Box mt={1}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="left">{unit.unitName}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {unit.results.map(result => (
                    <TableRow>
                      <TableCell align="left">
                        <DiagnosisLinkById studentCode={this.state.studentCode} subjectId={this.state.groupUnit.subjectId} id={result.id} typeDivision={result.typeDivision} createdAt={result.createdAt} />
                      </TableCell>
                      <TableCell align="right">★{result.understandingLevel}</TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        ))}
      </Grid>

    )
  }
}

export default DiagnosisData
