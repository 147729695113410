import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

class QrPrintButton extends Component {
  state = {
    officeCode: this.props.officeCode,
    officeName: this.props.officeName
  }

  render() {
    const ashigaruAuthToken = (`X` + this.state.officeCode).padEnd(40, '0')
    console.log(ashigaruAuthToken)

    return (
      <>
        <Button variant="outlined" size="small" href={`/qr-venue/${this.state.officeCode}/${encodeURIComponent(this.state.officeName)}/${ashigaruAuthToken}`} target="_brank">
          <Icon>crop_free</Icon>&nbsp;QRコード
        </Button>
        {` `}
        <Button variant="outlined" size="small" onClick={() => window.print()}>
          <Icon>print</Icon>&nbsp;印刷する
        </Button>
      </>
    )
  }
}

export default QrPrintButton
