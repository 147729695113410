import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
// import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'

const styles ={
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1
  }
}

class Header extends Component {
  // state = {
  //   employeeCode: ``,
  //   employeeName: ``,
  //   officeCode: ``,
  //   officeName: ``,
  //   error: '',
  // }

  render() {
    console.log(this.props)
    const { employeeName, officeCode, officeName } = this.props.loginUsers

    console.log(employeeName)

    return (
      <div className={this.props.classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" className={this.props.classes.title}>
              AI5教科管理 - 高校版 -
            </Typography>
            {` `}
            {employeeName && (
              <div
                onClick={() => {
                  this.props.history.push(`/venue-search/`)
                }}
              >
                店舗検索　
              </div>
            )}
            {employeeName && (
              <div
                onClick={() => {
                  this.props.history.push(`/student-search/${officeCode}`)
                }}
              >
                生徒検索　
              </div>
            )}
            {` `}
            {officeCode && (
              <div
                onClick={() => {
                  this.props.history.push(`/venue/${officeCode}`)
                }}
              >
                {officeName}　
              </div>
            )}
            {` `}
            {employeeName && (
              <div>
                {employeeName}　
              </div>
            )}
            {` `}
            <div>
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Header))
