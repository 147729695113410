import React, { Component } from 'react'

// import { format } from 'date-fns'
// import ja from 'date-fns/locale/ja'

import { withStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import '../../../node_modules/react-vis/dist/style.css'
import { XYPlot, HorizontalBarSeries, Hint } from 'react-vis'


const styles = {
  cellStyle: {
    paddingBottom: 32,
    verticalAlign: 'top'
  }
}

class JukenWholeData extends Component {
  state = {
    groupSubject: this.props.groupSubject,
  }

  render() {

    console.log(this.state.groupSubject)
    const graph = {
      marginLeft: `1rem`,
    }

    return (
      <Grid container spacing={5}>
        {this.state.groupSubject.results.map(result => (
        <Grid item xs={12} sm={6}>
          <Box mt={1}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3} align="left">{result.name}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" sm={6}>
                        <p><small mb={5}>基礎固め</small></p>
                        <p>{`　`}{result.lessonCountInfo.understand} / {result.lessonCountInfo.total} 授業</p>
                        <p>
                        <XYPlot width={10} height={50} margin={0}></XYPlot>
                        </p>
                      </TableCell>
                      <TableCell align="left" sm={6} className={this.props.classes.cellStyle}>
                        <p><small mb={5}>必修問題</small></p>
                        <p>
                        <XYPlot style={graph} width={200} height={50} margin={0}
                          xDomain={[ 0, `${result.standardContentCountInfo.total}` ]}
                          yDomain={[ 0, 1 ]}
                          stackBy="x"
                          padding={10}
                        >
                          <HorizontalBarSeries
                            color={null}
                            data={[
                              { x: 0,  y: 0 },
                              { x: `${result.standardContentCountInfo.monthBeforeLast}` / `${result.standardContentCountInfo.total}` * 10, y: 1 },
                            ]}
                            fill="#f2c94c"
                            opacity={1}
                            stroke="#f2c94c"
                            style={{}}
                          />
                          <HorizontalBarSeries
                            color={null}
                            data={[
                              { x: 0,  y: 0 },
                              { x: `${result.standardContentCountInfo.lastMonth - result.standardContentCountInfo.monthBeforeLast}` / `${result.standardContentCountInfo.total}` * 10, y: 1 },
                            ]}
                            fill="#219653"
                            opacity={1}
                            stroke="#219653"
                            style={{}}
                          />
                          <HorizontalBarSeries
                            color={null}
                            data={[
                              { x: 0,  y: 0 },
                              { x: `${result.standardContentCountInfo.thisMonth - result.standardContentCountInfo.lastMonth}` / `${result.standardContentCountInfo.total}` * 10, y: 1 },
                            ]}
                            fill="#2d9cdb"
                            opacity={1}
                            stroke="#2d9cdb"
                            style={{}}
                          />
                          <Hint size="small"
                            value={{
                              進捗: `先々月: ${result.standardContentCountInfo.monthBeforeLast} | 先月: ${result.standardContentCountInfo.lastMonth - result.standardContentCountInfo.monthBeforeLast} | 今月: ${result.standardContentCountInfo.thisMonth - result.standardContentCountInfo.lastMonth}`
                            }} />
                        </XYPlot>
                        </p>
                      </TableCell>
                      <TableCell align="left" sm={6} className={this.props.classes.cellStyle}>
                        <p><small mb={5}>対策問題</small></p>
                        {result.advancedContentCountInfo === null &&
                          <p>
                            <XYPlot width={200} height={50} margin={0}></XYPlot>
                          </p>
                        }
                        {result.advancedContentCountInfo !== null &&
                          <p>
                            <XYPlot style={graph} width={200} height={50} margin={0}
                              xDomain={[ 0, `${result.advancedContentCountInfo.total}` ]}
                              yDomain={[ 0, 1 ]}
                              stackBy="x"
                              padding={10}
                            >
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${result.advancedContentCountInfo.monthBeforeLast}` / `${result.advancedContentCountInfo.total}` * 10, y: 1 },
                                ]}
                                fill="#f2c94c"
                                opacity={1}
                                stroke="#f2c94c"
                                style={{}}
                              />
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${result.advancedContentCountInfo.lastMonth - result.advancedContentCountInfo.monthBeforeLast}` / `${result.advancedContentCountInfo.total}` * 10, y: 1 },
                                ]}
                                fill="#219653"
                                opacity={1}
                                stroke="#219653"
                                style={{}}
                              />
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${result.advancedContentCountInfo.thisMonth - result.advancedContentCountInfo.lastMonth}` / `${result.advancedContentCountInfo.total}` * 10, y: 1 },
                                ]}
                                fill="#2d9cdb"
                                opacity={1}
                                stroke="#2d9cdb"
                                style={{}}
                              />
                              <Hint
                                value={{
                                  進捗: `先々月: ${result.advancedContentCountInfo.monthBeforeLast} | 先月: ${result.advancedContentCountInfo.lastMonth - result.advancedContentCountInfo.monthBeforeLast} | 今月: ${result.advancedContentCountInfo.thisMonth - result.advancedContentCountInfo.lastMonth}`
                                }} />
                            </XYPlot>
                          </p>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        ))}
      </Grid>

    )
  }
}

export default withStyles(styles)(JukenWholeData)
