/* eslint-disable */
require('dotenv').config()

import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import App from './components/App'
import theme from './theme'
import { BrowserRouter } from 'react-router-dom'

import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { AUTH_TOKEN } from './constants'
import qs from 'qs'

const fistToken = () => {
  if (qs.parse(location.search.substr(1)).auth_token) {
    const authToken = qs.parse(location.search.substr(1)).auth_token
    // console.log(authToken)
    localStorage.setItem('authToken', authToken)
    return authToken
  } else {
    localStorage.setItem('authToken', 'devdevdevdevdevdevdevdevdevdevdevdevdevd')
    const authToken = localStorage.getItem('authToken')
    // console.log(authToken)
    return authToken
  }
}

const accessToken = fistToken()

const authLink = setContext((_, { headers }) => {
  return {
    fetchOptions: {
      credentials: 'include'
    },
    headers: {
      ...headers,
      // 'X-Auth-Token': accessToken ? `${accessToken}` : 'devdevdevdevdevdevdevdevdevdevdevdevdevd',
      'X-Auth-Token': accessToken && `${accessToken}`
    }
  }
})

const httpLink = createHttpLink({
  // uri: `${process.env.REACT_APP_GRAPHQL_API}`,
  uri: `https://dev2a-gbff.leap.trygroup.co.jp/graphql`,
  withCredentials: true
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
