import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
// import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

class JukenData extends Component {
  state = {
    groupUnit: this.props.groupUnit,
  }

  render() {

    console.log(this.state.groupUnit)

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Box mt={1}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableBody>
                  {this.state.groupUnit.results.map(unit => (
                    <TableRow>
                      <TableCell align="left">
                        {unit.name}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
      </Grid>

    )
  }
}

export default JukenData
