import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import TabJuken from './TabJuken'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default function TabYearsJuken (props) {
  const [value, setValue] = React.useState(0)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  return (
    <Box mt={10}>
      <AppBar position="static" color="#FFF">
        <Tabs variant="standard" value={value} onChange={handleChange} indicatorColor="white" textColor="primary">
          <Tab label="2022年度" />
          <Tab label="2021年度" />
          <Tab label="2020年度" />
        </Tabs>
      </AppBar>
      {value === 0 &&
        <TabContainer>
          <Box my={5}>
            <TabJuken studentCode={props.studentCode} fiscalYear="2022" />
          </Box>
        </TabContainer>}
      {value === 1 &&
        <TabContainer>
          <Box my={5}>
            <TabJuken studentCode={props.studentCode} fiscalYear="2021" />
          </Box>
        </TabContainer>}
      {value === 2 &&
        <TabContainer>
          <Box my={5}>
            <TabJuken studentCode={props.studentCode} fiscalYear="2020" />
          </Box>
        </TabContainer>}
    </Box>
  )
}
