import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// import { format } from 'date-fns'
// import ja from 'date-fns/locale/ja'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { renderRegularExam, renderSubjectCategory } from '../utils/try-convert'

// const sortSubjectCategoryId = function (a, b) {
//   return a.subjectCategoryId - b.subjectCategoryId
// }

const sortSubjectCategoryId = (a, b, desc = false) => {
  // eslint-disable-next-line
  if (a.subjectCategoryId !== a.subjectCategoryId && b.subjectCategoryId !== b.subjectCategoryId) return 0
  // eslint-disable-next-line
  if (a.subjectCategoryId !== a.subjectCategoryId) return 1
  // eslint-disable-next-line
  if (b.subjectCategoryId !== b.subjectCategoryId) return -1

  if (a.subjectCategoryId == null && b.subjectCategoryId == null) return 0
  if (a.subjectCategoryId == null) return 1
  if (b.subjectCategoryId == null) return -1

  if (a.subjectCategoryId === '' && b.subjectCategoryId === '') return 0
  if (a.subjectCategoryId === '') return 1
  if (b.subjectCategoryId === '') return -1

  var sig = desc ? 1 : -1
  return a.subjectCategoryId < b.subjectCategoryId ? sig : (a.subjectCategoryId > b.subjectCategoryId ? -sig : 0)
}

const TeikiResult = ({ data: { loading, error, hTeikiUserRegularExams, hTeikiUserSchedules, hTeikiSelectableSchedules } }) => {
  console.log(hTeikiSelectableSchedules)
  console.log(hTeikiUserSchedules)
  console.log(hTeikiUserRegularExams)

  if (error) return <p>定期テスト結果情報の取得に失敗しました。</p>
  if (!loading && !hTeikiUserRegularExams.length) {
    return <p>定期テスト結果が入力されていません。</p>
  }

  if (!loading) {

    const selectableSchedules = hTeikiSelectableSchedules
    // const userRegularExams = hTeikiUserRegularExams
    const userSchedules = hTeikiUserSchedules

    const pickupDate = (scheduleId) => {
      const targetSchedule = selectableSchedules.filter(x => x.regularExamLabelCode === scheduleId)
      const targetId = targetSchedule[0].id
      const targetDate = userSchedules.filter(x => x.scheduleId === targetId)
      if (targetDate[0]) {
        return targetDate[0].period
      } else {
        return `--`
      }
    }

    // console.log(pickupDate('1'))

    return (
      <>
      {hTeikiUserRegularExams.map(exam => (
        <Box mt={5}>
          <Typography variant="h6" gutterBottom paragraph>
            {renderRegularExam(exam.regularExamLabelCode)}{`　`}<small>{pickupDate(exam.regularExamLabelCode)}</small>
          </Typography>
          <Paper my={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left">教科</TableCell>
                  <TableCell component="th" align="left">テスト名</TableCell>
                  <TableCell component="th" align="left">得点</TableCell>
                  <TableCell component="th" align="left">学校平均点</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exam.userRegularExamScores.sort(sortSubjectCategoryId).map(score => (
                  <TableRow>
                    <TableCell align="left">{renderSubjectCategory(score.subjectCategoryId)}</TableCell>
                    <TableCell align="left">{score.name}</TableCell>
                    <TableCell align="left">{score.score}</TableCell>
                    <TableCell align="left">{score.averageScore}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      ))}
      </>
    )
  }
  return <p>定期テスト結果情報を取得中...</p>
}

TeikiResult.propTypes = {
  studentCode: PropTypes.number,
  fiscalYear: PropTypes.number
}

export const ResultQuery = gql`
  query store ($studentCode: ID!, $fiscalYear: Int) {
    hTeikiSelectableSchedules(studentCode: $studentCode){
      id
      regularExamLabelCode
      title
    }
    hTeikiUserSchedules(studentCode: $studentCode, fiscalYear: $fiscalYear){
      id
      period
      scheduleId
    }
    hTeikiUserRegularExams (studentCode: $studentCode, fiscalYear: $fiscalYear){
      fiscalYear
      id
      regularExamLabelCode
      userRegularExamScores {
        id
        subjectCategoryId
        name
        score
        averageScore
      }
    }
  }
`

export default graphql(ResultQuery, {
  options: (props) => ({
    variables: {
      studentCode: props.studentCode,
      fiscalYear: props.fiscalYear
    }
  })
})(TeikiResult)
