import React from 'react'
import PropTypes from 'prop-types'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import JukenWholeData from './JukenWholeData'

import { renderHighSubject } from '../utils/try-convert'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const JukenWholeLists = ({ data: { loading, error, hJukenUnitCategories, variables } }) => {
  const [value, setValue] = React.useState(0)
  console.log(variables.studentCode)
  // const studentCode = variables.studentCode

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  if (error) return <p>全体の進捗を取得できません。システム管理者にお問い合せください。</p>
  if (!loading) {

    const wholeData = hJukenUnitCategories
    console.log(wholeData)

    const groupSubject = wholeData.reduce((result, current) => {
      const element = result.find((p) => {
        return p.subjectId === current.subjectId
      })
      if (element) {
        element.results.push({
          id: current.id,
          name: current.name,
          lessonCountInfo: current.lessonCountInfo,
          standardContentCountInfo: current.standardContentCountInfo,
          advancedContentCountInfo: current.advancedContentCountInfo
        })
      } else {
        result.push({
          subjectId: current.subjectId,
          results: [{
            id: current.id,
            name: current.name,
            lessonCountInfo: current.lessonCountInfo,
            standardContentCountInfo: current.standardContentCountInfo,
            advancedContentCountInfo: current.advancedContentCountInfo
          }]
        })
      }
      return result
    }, [])

    console.log(groupSubject)

    return (
      <>
        <Box my={5}>
          <Tabs value={value} onChange={handleChange} indicatorColor="default" textColor="primary" variant="scrollable" scrollButtons="auto">
            {groupSubject.map(result => (
              <Tab label={renderHighSubject(result.subjectId)} key={result.subjectId} id={result.subjectId} />
            ))}
          </Tabs>
          {value === 0 &&
            <TabContainer>
              {groupSubject[0] && <JukenWholeData groupSubject={groupSubject[0]} />}
            </TabContainer>}
          {value === 1 &&
            <TabContainer>
              {groupSubject[1] && <JukenWholeData groupSubject={groupSubject[1]} />}
            </TabContainer>}
          {value === 2 &&
            <TabContainer>
              {groupSubject[2] && <JukenWholeData groupSubject={groupSubject[2]} />}
            </TabContainer>}
          {value === 3 &&
            <TabContainer>
              {groupSubject[3] && <JukenWholeData groupSubject={groupSubject[3]} />}
            </TabContainer>}
          {value === 4 &&
            <TabContainer>
              {groupSubject[4] && <JukenWholeData groupSubject={groupSubject[4]} />}
            </TabContainer>}
          {value === 5 &&
            <TabContainer>
              {groupSubject[5] && <JukenWholeData groupSubject={groupSubject[5]} />}
            </TabContainer>}
          {value === 6 &&
            <TabContainer>
              {groupSubject[6] && <JukenWholeData groupSubject={groupSubject[6]} />}
            </TabContainer>}
          {value === 7 &&
            <TabContainer>
              {groupSubject[7] && <JukenWholeData groupSubject={groupSubject[7]} />}
            </TabContainer>}
          {value === 8 &&
            <TabContainer>
              {groupSubject[8] && <JukenWholeData groupSubject={groupSubject[8]} />}
            </TabContainer>}
          {value === 9 &&
            <TabContainer>
              {groupSubject[9] && <JukenWholeData groupSubject={groupSubject[9]} />}
            </TabContainer>}
          {value === 10 &&
            <TabContainer>
              {groupSubject[10] && <JukenWholeData groupSubject={groupSubject[10]} />}
            </TabContainer>}
          {value === 11 &&
            <TabContainer>
              {groupSubject[11] && <JukenWholeData groupSubject={groupSubject[11]} />}
            </TabContainer>}
          {value === 12 &&
            <TabContainer>
              {groupSubject[12] && <JukenWholeData groupSubject={groupSubject[12]} />}
            </TabContainer>}
          {value === 13 &&
            <TabContainer>
              {groupSubject[13] && <JukenWholeData groupSubject={groupSubject[13]} />}
            </TabContainer>}
          {value === 14 &&
            <TabContainer>
              {groupSubject[14] && <JukenWholeData groupSubject={groupSubject[14]} />}
            </TabContainer>}
          {value === 15 &&
            <TabContainer>
              {groupSubject[15] && <JukenWholeData groupSubject={groupSubject[15]} />}
            </TabContainer>}
          {value === 16 &&
            <TabContainer>
              {groupSubject[16] && <JukenWholeData groupSubject={groupSubject[16]} />}
            </TabContainer>}
        </Box>
      </>
    )
  }
  return <p>全体の進捗を取得中...</p>
}

JukenWholeLists.propTypes = {
  studentCode: PropTypes.number
}

export const diagnosisQuery = gql`
  query store($studentCode: ID!) {
    hJukenUnitCategories(studentCode: $studentCode){
      id
      lessonCountInfo {
        total
        understand
      }
      name
      standardContentCountInfo {
        thisMonth
        lastMonth
        monthBeforeLast
        total
      }
      advancedContentCountInfo {
        lastMonth
        monthBeforeLast
        thisMonth
        total
      }
      subjectId
    }
  }
`

export default graphql(diagnosisQuery, {
  options: (props) => ({
    variables: {
      studentCode: props.studentCode
    }
  })
})(JukenWholeLists)

