import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import PrintButton from '../utils/print'
import JukenWholeLists from './JukenWholeLists'
import JukenLists from './JukenLists'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const useStyles = makeStyles({
  yellow: {
    color: '#f2c94c',
  },
  green: {
    color: '#219653',
  },
  blue: {
    color: '#2d9cdb',
  },
})

export default function TabTeiki (props) {
  const [value, setValue] = React.useState(0)
  const classes = useStyles();


  function handleChange (event, newValue) {
    setValue(newValue)
  }

  return (
    <Box my={3}>
      <AppBar position="static" color="#FFF">
        <Tabs variant="fullWidth" value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="月ごとの学習" />
          <Tab label="全体の進捗" />
        </Tabs>
      </AppBar>
      {value === 0 &&
        <TabContainer>
          <Box my={5}>
            <PrintButton />
            <Typography variant="h6" gutterBottom paragraph>
              設定中の受験対策
            </Typography>
            <JukenLists studentCode={props.studentCode} fiscalYear={props.fiscalYear} />
          </Box>
        </TabContainer>}
      {value === 1 &&
        <TabContainer>
          <Box my={5}>
            <PrintButton />
            <Grid container>
              <Grid item sm={2}>
                <Typography variant="h6" gutterBottom paragraph>
                  設定中の科目と分野
                </Typography>
              </Grid>
              <Grid item>
                <small><span className={classes.yellow}>■</span>先々月以前の合計　<span className={classes.green}>■</span>先月　<span className={classes.blue}>■</span>今月</small>
              </Grid>
            </Grid>
            <JukenWholeLists studentCode={props.studentCode} />
          </Box>
        </TabContainer>}
    </Box>
  )
}
