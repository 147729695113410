import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { renderHighSubject } from '../utils/try-convert'
import TeikiData from './TeikiData'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const TeikiSubjects = (groupScheduled) => {
  const [value, setValue] = React.useState(0)
  // console.log(groupScheduled)
  const scheduledList = groupScheduled.groupScheduled
  // console.log(scheduledList)

  const subjectList = scheduledList.reduce((result, current) => {
      result = current;
      return result;
  }, {});

  let selectedSchedule = []

  if (subjectList.userScheduleSubjects === undefined) {
    selectedSchedule = []
  } else {
    selectedSchedule = subjectList.userScheduleSubjects
  }

  console.log(selectedSchedule)

  const groupUnit = selectedSchedule.map(item => {
    const units = item.units.reduce((result, current) => {
      const element = result.find((p) => {
        return p.unitCategoryId === current.unitCategoryId
      })
      if (element) {
        element.results.push({
          id: current.id,
          name: current.name,
          userUnit: current.userUnit
        })
      } else {
        result.push({
          unitCategoryId: current.unitCategoryId,
          results: [{
            id: current.id,
            name: current.name,
            userUnit: current.userUnit
          }]
        })
      }
      return result
    }, [])
    return ({
      subjectId: item.subjectId,
      units
    })
  })

  console.log(groupUnit)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  return (
    <>
      <Box>
        <Tabs value={value} onChange={handleChange} indicatorColor="default" textColor="primary" variant="scrollable" scrollButtons="auto">
          {selectedSchedule && selectedSchedule.map(result => (
            <Tab label={renderHighSubject(result.subjectId)} />
          ))}
        </Tabs>
        {value === 0 &&
          <TabContainer>
            {groupUnit[0] && <TeikiData groupUnit={groupUnit[0]} />}
          </TabContainer>}
        {value === 1 &&
          <TabContainer>
            {groupUnit[1] && <TeikiData groupUnit={groupUnit[1]} />}
          </TabContainer>}
        {value === 2 &&
          <TabContainer>
            {groupUnit[2] && <TeikiData groupUnit={groupUnit[2]} />}
          </TabContainer>}
        {value === 3 &&
          <TabContainer>
            {groupUnit[3] && <TeikiData groupUnit={groupUnit[3]} />}
          </TabContainer>}
        {value === 4 &&
          <TabContainer>
            {groupUnit[4] && <TeikiData groupUnit={groupUnit[4]} />}
          </TabContainer>}
        {value === 5 &&
          <TabContainer>
            {groupUnit[5] && <TeikiData groupUnit={groupUnit[5]} />}
          </TabContainer>}
        {value === 6 &&
          <TabContainer>
            {groupUnit[6] && <TeikiData groupUnit={groupUnit[6]} />}
          </TabContainer>}
        {value === 7 &&
          <TabContainer>
            {groupUnit[7] && <TeikiData groupUnit={groupUnit[7]} />}
          </TabContainer>}
        {value === 8 &&
          <TabContainer>
            {groupUnit[8] && <TeikiData groupUnit={groupUnit[8]} />}
          </TabContainer>}
        {value === 9 &&
          <TabContainer>
            {groupUnit[9] && <TeikiData groupUnit={groupUnit[9]} />}
          </TabContainer>}
        {value === 10 &&
          <TabContainer>
            {groupUnit[10] && <TeikiData groupUnit={groupUnit[10]} />}
          </TabContainer>}
        {value === 11 &&
          <TabContainer>
            {groupUnit[11] && <TeikiData groupUnit={groupUnit[11]} />}
          </TabContainer>}
        {value === 12 &&
          <TabContainer>
            {groupUnit[12] && <TeikiData groupUnit={groupUnit[12]} />}
          </TabContainer>}
        {value === 13 &&
          <TabContainer>
            {groupUnit[13] && <TeikiData groupUnit={groupUnit[13]} />}
          </TabContainer>}
        {value === 14 &&
          <TabContainer>
            {groupUnit[14] && <TeikiData groupUnit={groupUnit[14]} />}
          </TabContainer>}
        {value === 15 &&
          <TabContainer>
            {groupUnit[15] && <TeikiData groupUnit={groupUnit[15]} />}
          </TabContainer>}
      </Box>
    </>
  )
}

export default TeikiSubjects
