import React from 'react'
import PropTypes from 'prop-types'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TeikiSubjects from './TeikiSubjects'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const TeikiLists = ({ data: { loading, error, hTeikiSelectableSchedules, hTeikiUserSchedules, variables } }) => {
  const [value, setValue] = React.useState(0)
  console.log(variables.studentCode)
  // const studentCode = variables.studentCode

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  if (error) return <p>スケジュールを取得できません。システム管理者にお問い合せください。</p>
  if (!loading) {

    const sortScheduled = function (a, b) {
      if (Number(a.scheduleId) < Number(b.scheduleId)) return -1
      if (Number(a.scheduleId) > Number(b.scheduleId)) return 1
    }

    const selectableSchedules = hTeikiSelectableSchedules
    const userSchedules = hTeikiUserSchedules.sort(sortScheduled)
    // console.log(selectableSchedules)
    console.log(userSchedules)

    const scheduleId = (id) => {
      if (userSchedules.filter(x => x.scheduleId === id).length) {
        return true
      } else {
        return false
      }
    }

    return (
      <>
        <Box my={5}>
          <Tabs value={value} onChange={handleChange} indicatorColor="default" textColor="primary" variant="scrollable" scrollButtons="auto">
            {selectableSchedules.map(result => (
              <Tab label={result.title} key={result.id} id={result.id} disabled={!scheduleId(`${result.id}`)} />
            ))}
          </Tabs>
          <Box my={5}>
            <Typography variant="h6" gutterBottom paragraph>
              科目別学習結果一覧
            </Typography>
          </Box>
          {value === 0 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '1') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '1')} />}
              {userSchedules.filter(x => x.scheduleId === '9') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '9')} />}
            </TabContainer>}
          {value === 1 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '2') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '2')} />}
              {userSchedules.filter(x => x.scheduleId === '10') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '10')} />}
            </TabContainer>}
          {value === 2 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '3') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '3')} />}
              {userSchedules.filter(x => x.scheduleId === '11') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '11')} />}
            </TabContainer>}
          {value === 3 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '4') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '4')} />}
              {userSchedules.filter(x => x.scheduleId === '12') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '12')} />}
            </TabContainer>}
          {value === 4 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '5') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '5')} />}
              {userSchedules.filter(x => x.scheduleId === '13') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '13')} />}
            </TabContainer>}
          {value === 5 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '6') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '6')} />}
              {userSchedules.filter(x => x.scheduleId === '14') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '14')} />}
            </TabContainer>}
          {value === 6 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '7') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '7')} />}
              {userSchedules.filter(x => x.scheduleId === '15') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '15')} />}
            </TabContainer>}
          {value === 7 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '8') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '8')} />}
              {userSchedules.filter(x => x.scheduleId === '16') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '16')} />}
            </TabContainer>}
          {value === 8 &&
            <TabContainer >
              {userSchedules.filter(x => x.scheduleId === '17') &&
                <TeikiSubjects groupScheduled={userSchedules.filter(x => x.scheduleId === '17')} />}
            </TabContainer>}
        </Box>
      </>
    )
  }
  return <p>スケジュールを取得中...</p>
}

TeikiLists.propTypes = {
  studentCode: PropTypes.number,
  fiscalYear: PropTypes.number
}

export const diagnosisQuery = gql`
  query store($studentCode: ID!,  $fiscalYear: Int) {
    hTeikiSelectableSchedules(studentCode: $studentCode){
      id
      title
    }
    hTeikiUserSchedules(studentCode: $studentCode, fiscalYear: $fiscalYear){
      id
      period
      scheduleId
      userScheduleSubjects {
        id
        subjectId
        units {
          id
          name
          unitCategoryId
          userUnit {
            id
            understandingLevelForCheck
            understandingLevelForShindan
          }
        }
      }
    }
  }
`

export default graphql(diagnosisQuery, {
  options: (props) => ({
    variables: {
      studentCode: props.studentCode,
      fiscalYear: props.fiscalYear
    }
  })
})(TeikiLists)

