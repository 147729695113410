import React from 'react'
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import PrintButton from '../utils/print'
import TeikiResult from './TeikiResult'
import TeikiLists from './TeikiLists'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default function TabTeiki (props) {
  const [value, setValue] = React.useState(0)

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  return (
    <Box my={3}>
      <AppBar position="static" color="#FFF">
        <Tabs variant="fullWidth" value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="学習内容" />
          <Tab label="定期テスト結果" />
        </Tabs>
      </AppBar>
      {value === 0 &&
        <TabContainer>
          <Box my={5}>
            <PrintButton />
            <Typography variant="h6" gutterBottom paragraph>
              設定中の定期テスト対策
            </Typography>
            <TeikiLists studentCode={props.studentCode} fiscalYear={props.fiscalYear} />
          </Box>
        </TabContainer>}
      {value === 1 &&
        <TabContainer>
          <Box my={5}>
            <PrintButton />
            <TeikiResult studentCode={props.studentCode} fiscalYear={props.fiscalYear} />
          </Box>
        </TabContainer>}
    </Box>
  )
}
