import React, { Component } from 'react'

import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import axios from 'axios'
import FileSaver from 'file-saver'

import Button from '@material-ui/core/Button'

const GET_PDF = gql`
  query store($studentCode: ID!, $id: ID!, $ashigaruApplicationTypeDivision: ID!) {
    learningDiagnosticSheetById(studentCode: $studentCode, id: $id, ashigaruApplicationTypeDivision: $ashigaruApplicationTypeDivision){
      studentCode
      subjectId
      id
      ashigaruApplicationTypeDivision
    }
  }
`

class DiagnosisLinkById extends Component {
  state = {
    studentCode: this.props.studentCode,
    subjectId: this.props.subjectId,
    id: this.props.id,
    ashigaruApplicationTypeDivision: this.props.typeDivision,
    createdAt: this.props.createdAt
  }

  render () {
    return (
      <>
        <Button color="primary" onClick={() => this._getFile()}>
          {format(this.state.createdAt, 'YYYY年 MMMDo', {locale: ja})}
        </Button>
      </>
    )
  }

  _getFile = async () => {
    const { studentCode, id, ashigaruApplicationTypeDivision } = this.state

    const result = await this.props.client.query({
      query: GET_PDF,
      variables: { studentCode, id, ashigaruApplicationTypeDivision },
    })
    const store = result.data.learningDiagnosticSheetById
    // console.log(store)
    const url = `https://dev2a-bff.leap.trygroup.co.jp/customers/students/tryit/diagnosis/get/?student_code=${store.studentCode}&subject_id=${store.subjectId}&id=${store.id}&ashigaru_application_type_division=${store.ashigaruApplicationTypeDivision}`

    // const fistToken = 'devdevdevdevdevdevdevdevdevdevdevdevdevd'
    const fistToken = localStorage.getItem('authToken')
    console.log(fistToken)

    await axios.get(url, {
      headers: { 'X-Auth-Token': `${fistToken}` },
      responseType: 'blob'
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })

      const contentDisposition = response.headers['content-disposition']
      // console.log(contentDisposition)

      let fileName = 'unknown'
      if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch.length === 2)
              fileName = fileNameMatch[1]
      }

      FileSaver.saveAs(blob, fileName)
    })
  }
}

export default withApollo(DiagnosisLinkById)
