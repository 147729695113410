import React from 'react'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

// import { renderMode } from './try-convert'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headRows = [
  { id: 'studentName', numeric: false, disablePadding: false, label: '生徒氏名' },
  { id: 'schoolYearName', numeric: false, disablePadding: false, label: '学年' },
  { id: 'studentCode', numeric: false, disablePadding: false, label: '生徒コード' },
  { id: 'staffName', numeric: false, disablePadding: false, label: '担当社員名' },
  { id: 'ashigaruApplicationUseAt', numeric: false, disablePadding: false, label: '最終利用日' },
  { id: 'ashigaruApplicationTypeDivision', numeric: false, disablePadding: false, label: '利用詳細' },
  { id: 'studentCodeLink', numeric: false, disablePadding: false, label: '応対履歴' },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export default function EnhancedTable({data, officeCode}) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('schoolYearName')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const rows = data

  console.log(data)

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  function handleClick(event, id) {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div>
      {rows.length &&
        <Box mb={3}>
          <p>対象店舗: {rows[0].officialOfficeName}（{rows.length}件）</p>
        </Box>}
      <Paper>
        <div>
          <Table
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.studentCode)

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.studentCode)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.studentCode}
                      selected={isItemSelected}
                    >
                      {row.schoolYearCode === '21' || row.schoolYearCode === '22' || row.schoolYearCode === '23' ?
                        (<TableCell align="left" component="th" scope="row">
                          {row.ashigaruApplicationTypeDivision === '30' &&
                            <a href={`/student/${row.studentCode}/t`} user={row} target="_brank">{row.studentName}</a>}
                          {row.ashigaruApplicationTypeDivision === '31' &&
                            <a href={`/student/${row.studentCode}/j`} user={row} target="_brank">{row.studentName}</a>}
                          {!row.ashigaruApplicationTypeDivision &&
                            `${row.studentName}`}
                        </TableCell>) :
                        (<TableCell align="left" component="th" scope="row">
                          {row.ashigaruApplicationTypeDivision === '30' &&
                            <a href={`/student/${row.studentCode}/t`} user={row} target="_brank">{row.studentName}</a>}
                          {row.ashigaruApplicationTypeDivision === '31' &&
                            <a href={`/student/${row.studentCode}/j`} user={row} target="_brank">{row.studentName}</a>}
                          {!row.ashigaruApplicationTypeDivision &&
                            <a href={`/student/${row.studentCode}`} user={row} target="_brank">{row.studentName}</a>}
                        </TableCell>)}
                      <TableCell align="left">{row.schoolYearName}</TableCell>
                      <TableCell align="left">{row.studentCode}</TableCell>
                      <TableCell align="left">{row.staffName}</TableCell>
                      <TableCell align="left">{!row.ashigaruApplicationUseAt ? `--` : `${format(row.ashigaruApplicationUseAt, 'YYYY年 MMMDo HH時mm分', {locale: ja})}`}</TableCell>
                      <TableCell align="left">
                        {row.isMiddleSchoolStudent === true ? (<Chip size="small" label="中学" color="secondary" />) : (<Chip size="small" label="中学" disabled />)}
                        {` `}
                        {row.ashigaruApplicationTypeDivision === '30' || row.ashigaruApplicationTypeDivision === '31'  ? (<Chip size="small" label="高校" color="primary" />) : (<Chip size="small" label="高校" disabled  />)}
                      </TableCell>
                      <TableCell align="left">
                        <Button size="small" variant="outlined" href={`http://172.27.3.20/rnr/RNR0080.aspx?SIT_CD=${row.studentCode}`} target="_blank" rel="noopener noreferrer">
                          応対履歴
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': '前へ',
          }}
          nextIconButtonProps={{
            'aria-label': '次へ',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
