import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StudentHeader from './student/StudentHeader'
import TabYearsTeiki from './student/TabYearsTeiki'
import TabYearsJuken from './student/TabYearsJuken'
import TabDiagnosis from './student/TabDiagnosis'

class Student extends Component {
  state = {
    props: this.props,
  }

  render () {
    const { studentCode, viewMode } = this.state.props.match.params
    console.log(this.state.props)

    return (
      <>
        <StudentHeader studentCode={studentCode} viewMode={viewMode} />
        {!viewMode &&
          <TabYearsTeiki studentCode={studentCode} />}
        {viewMode === 't' &&
          <TabYearsTeiki studentCode={studentCode} />}
        {viewMode === 'j' &&
          <TabYearsJuken studentCode={studentCode} />}
        {viewMode === 'd' &&
          <TabDiagnosis studentCode={studentCode} />}
      </>
    )
  }
}

Student.propTypes = {
  studentCode: PropTypes.number.isRequired,
}

export default Student
