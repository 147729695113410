import React from 'react'
import PropTypes from 'prop-types'

import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import DiagnosisData from './DiagnosisData'

function TabContainer (props) {
  return (
    <Typography component="div" style={{ marginTop: 16 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const TabDiagnosis = ({ data: { loading, error, userShindanResults, variables } }) => {
  const [value, setValue] = React.useState(0)
  // console.log(variables.studentCode)
  const studentCode = variables.studentCode

  function handleChange (event, newValue) {
    setValue(newValue)
  }

  if (error) return <p>学習診断結果を取得できません。システム管理者にお問い合せください。</p>
  if (!loading) {

    const sortSubject = function (a, b) {
      if (Number(a.subjectId) < Number(b.subjectId)) return -1
      if (Number(a.subjectId) > Number(b.subjectId)) return 1
      if (Number(a.unitCategoryId) < Number(b.unitCategoryId)) return -1
      if (Number(a.unitCategoryId) > Number(b.unitCategoryId)) return 1
      if (a.createdAt < b.createdAt) return 1
      if (a.createdAt > b.createdAt) return -1
    }

    const teikiResult = userShindanResults.hTeiki
    const jukenResult = userShindanResults.hJuken
    console.log(teikiResult)

    const tResult = teikiResult.map(item => {
      return ({
        ...item,
        typeDivision: 30
      })
    })

    const jResult = jukenResult.map(item => {
      return ({
        ...item,
        typeDivision: 31
      })
    })

    console.log(tResult)

    const allResults = tResult.concat(jResult)
    const sortedResults = allResults.sort(sortSubject)
    // console.log(sortedResults)

    const groupSubject = sortedResults.reduce((result, current) => {
      const element = result.find((p) => {
        return p.subjectId === current.subjectId
      })
      if (element) {
        element.units.push({
          typeDivision: current.typeDivision,
          id: current.id,
          unitId: current.unitCategoryId,
          unitName: current.unitCategoryName,
          createdAt: current.createdAt,
          understandingLevel: current.understandingLevel
        })
      } else {
        result.push({
          subjectId: current.subjectId,
          subjectName: current.subjectName,
          units: [
            {
              typeDivision: current.typeDivision,
              id: current.id,
              unitId: current.unitCategoryId,
              unitName: current.unitCategoryName,
              createdAt: current.createdAt,
              understandingLevel: current.understandingLevel
            }
          ]
        })
      }
      return result
    }, [])

    // console.log(groupSubject)

    const groupUnit = groupSubject.map(item => {
      const units = item.units.reduce((result, current) => {
        const element = result.find((p) => {
          return p.unitId === current.unitId
        })
        if (element) {
          element.results.push({
            typeDivision: current.typeDivision,
            id: current.id,
            createdAt: current.createdAt,
            understandingLevel: current.understandingLevel
          })
        } else {
          result.push({
            unitId: current.unitId,
            unitName: current.unitName,
            results: [{
              typeDivision: current.typeDivision,
              id: current.id,
              createdAt: current.createdAt,
              understandingLevel: current.understandingLevel
            }]
          })
        }
        return result
      }, [])
      return ({
        subjectId: item.subjectId,
        subjectName: item.subjectName,
        units
      })
    })

    console.log(groupUnit)
    console.log(groupUnit.length)

    return (
      <>
        <Box my={10}>
          <Typography variant="h6" gutterBottom paragraph>
            学習診断結果
          </Typography>
        </Box>
        {groupUnit.length === 0 ? (
          <p>学習診断結果がありません。</p>
          ) : (
          <Box>
            <Tabs value={value} onChange={handleChange} indicatorColor="default" textColor="primary" variant="scrollable" scrollButtons="auto">
              {groupUnit.map(result => (
                <Tab label={result.subjectName} />
              ))}
            </Tabs>
            {value === 0 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[0]} studentCode={studentCode} />
              </TabContainer>}
            {value === 1 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[1]} studentCode={studentCode} />
              </TabContainer>}
            {value === 2 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[2]} studentCode={studentCode} />
              </TabContainer>}
            {value === 3 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[3]} studentCode={studentCode} />
              </TabContainer>}
            {value === 4 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[4]} studentCode={studentCode} />
              </TabContainer>}
            {value === 5 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[5]} studentCode={studentCode} />
              </TabContainer>}
            {value === 6 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[6]} studentCode={studentCode} />
              </TabContainer>}
            {value === 7 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[7]} studentCode={studentCode} />
              </TabContainer>}
            {value === 8 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[8]} studentCode={studentCode} />
              </TabContainer>}
            {value === 9 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[9]} studentCode={studentCode} />
              </TabContainer>}
            {value === 10 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[10]} studentCode={studentCode} />
              </TabContainer>}
            {value === 11 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[11]} studentCode={studentCode} />
              </TabContainer>}
            {value === 12 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[12]} studentCode={studentCode} />
              </TabContainer>}
            {value === 13 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[13]} studentCode={studentCode} />
              </TabContainer>}
            {value === 14 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[14]} studentCode={studentCode} />
              </TabContainer>}
            {value === 15 &&
              <TabContainer>
                <DiagnosisData groupUnit={groupUnit[15]} studentCode={studentCode} />
              </TabContainer>}
          </Box>
        )}
      </>
    )
  }
  return <p>学習診断結果を取得中...</p>
}

TabDiagnosis.propTypes = {
  studentCode: PropTypes.number
}

export const diagnosisQuery = gql`
  query store($studentCode: ID!) {
    userShindanResults(studentCode: $studentCode){
      hTeiki {
        id
        createdAt
        subjectId
        subjectName
        unitCategoryId
        unitCategoryName
        displayUnderstandingLevel
        understandingLevel
      }
      hJuken {
        id
        createdAt
        subjectId
        subjectName
        unitCategoryId
        unitCategoryName
        displayUnderstandingLevel
        understandingLevel
      }
    }
  }
`

export default graphql(diagnosisQuery, {
  options: (props) => ({
    variables: {
      studentCode: props.studentCode
    }
  })
})(TabDiagnosis)

