export function renderApps (appCode) {
  switch (appCode) {
    case '20': return '中学版'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '30': return '定期テスト対策'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '31': return '受験対策'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderTablet (tabletCode) {
  switch (tabletCode) {
    case '00': return '未割当て'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '01': return '持ち帰り'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '02': return '据え置き'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderGrade (gradeCode) {
  switch (gradeCode) {
    case '21': return '中1'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '22': return '中2'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '23': return '中3'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '28': return '中卒'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '29': return '高校受験準備'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderMember (tabletOnly) {
  switch (tabletOnly) {
    case true: return 'タブレット会員'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case false: return '本会員'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderWeeklyStatus (examinationStatus) {
  switch (examinationStatus) {
    case 0: return '–'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 1: return '◯'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 2: return '◎'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '–'
  }
}

export function renderChoiceLevel (simpleChoiceDeviationValue) {
  switch (simpleChoiceDeviationValue) {
    case 50: return '〜中位校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 55: return '中上位校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 60: return '上位校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case 65: return '難関校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable   no-unreachable */
    case 999: return '最難関校'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderMidSubject (subjectId) {
  switch (subjectId) {
    case '1': return '英語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '数学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '理科'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '地理'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '歴史'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '6': return '公民'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '7': return '国語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderHighSubject (subjectId) {
  switch (subjectId) {
    case '1': return '英文法'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '数学Ⅰ'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '数学Ａ'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '数学Ⅱ'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '数学Ｂ'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '6': return '数学Ⅲ'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '7': return '物理基礎'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '8': return '物理'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '9': return '化学基礎'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '10': return '化学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '11': return '生物基礎'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '12': return '生物'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '13': return '日本史'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '14': return '世界史'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '15': return '古文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '16': return '漢文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '17': return '長文読解'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '18': return '会話文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '19': return '英作文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderHighUnit (unitId) {
  switch (unitId) {
    case '1': return '文法'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '語法'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '数と式'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '2次関数'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '図形と計量'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '6': return 'データ分析'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '7': return '場合の数・確率'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '8': return '整数の性質'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '9': return '図形の性質'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '10': return 'いろいろな式'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '11': return '図形と方程式'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '12': return '図形と方程式'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '13': return '指数関数・対数関数'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '14': return '微分法と積分法'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '15': return '数列'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '16': return 'ベクトル'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '17': return '複素数平面'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '18': return '2次曲線／種々の関数'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '19': return '極限'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '20': return '微積分'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '21': return '力学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '22': return '熱力学／波動／電磁気／原子'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '23': return '力学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '24': return '熱力学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '25': return '波動'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '26': return '電磁気'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '27': return '原子'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '28': return '物質の構成'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '29': return '物質の変化'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '30': return '理論化学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '31': return '無機化学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '32': return '有機化学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '33': return '高分子化合物'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '34': return '細胞／遺伝'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '35': return '体内環境の維持'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '36': return '生物の多様性／生態系'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '37': return '細胞／代謝'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '38': return '遺伝／生殖／動物の発生／植物の発生'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '39': return '植物生理／動物生理'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '40': return '個体群／生態／進化／分類'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '41': return '原始・古代'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '42': return '中世'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '43': return '近世'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '44': return '近現代'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '45': return '古代オリエント・インド・地中海世界'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '46': return '東アジア・イスラム世界の形成と発展'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '47': return '中世世界の成立と発展'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '48': return 'イスラム世界の繁栄／ルネサンス～絶対王政'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '49': return 'イギリス市民革命～ウィーン体制後'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '50': return '19世紀欧米諸国と帝国主義'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '51': return '二つの世界大戦'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '52': return '戦後の歴史'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '53': return '古文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '54': return '漢文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '55': return '長文読解'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '56': return '会話文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '57': return '英作文'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return '--'
  }
}

export function renderSubjectCategory (subjectId) {
  switch (subjectId) {
    case '1': return '英語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '数学'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '理科'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '社会'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '国語'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    default: return 'その他'
  }
}

export function renderRegularExam (regularExamLabelCode) {
  switch (regularExamLabelCode) {
    case '1': return '1学期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '2': return '1学期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '3': return '2学期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '4': return '2学期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '5': return '3学期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '11': return '前期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '12': return '前期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '13': return '後期 中間テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */
    case '14': return '後期 期末テスト'
      /* eslint-disable no-unreachable */
      break
      /* eslint-enable no-unreachable */

    default: return '--'
  }
}

