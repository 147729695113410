import React from 'react'

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'

import VenueHeader from './venue/Head'
import ComponentSearchUsers from './search/ComponentSearchUsers';

const StudentSearch = ({ data: { loading, error, officeSearch } }) => {
  if (loading) return <p>コンポーネントを読み込んでいます。</p>
  if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>
  if (!loading) {

    return (
      <>
        {officeSearch.map(office => (
        <Grid container spacing={3}>
          <VenueHeader officeCode={office.officeCode} officeName={office.officeName} />
          <Grid item xs={12}>
            <ComponentSearchUsers officeCode={office.officeCode} />
          </Grid>
        </Grid>
        ))}
      </>
    )
  }
  return <p>店舗情報を取得中...</p>
}

export const venueQuery = gql`
  query officeSearch ($officeCode: String!) {
    officeSearch (officeCode: $officeCode){
      officeCode
      officeName
      serviceType
    }
  }
`

// export withApollo(StudentSearch)
export default graphql(venueQuery, {
  options: ({ match }) => ({
    variables: {
      officeCode: match.params.officeCode
    }
  })
})(StudentSearch)
